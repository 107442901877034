import React from "react";

const Header = () => {
  return (
    <center className="st-header">
      <h1>Santa Tracker</h1>
    </center>
  );
};

export default Header;
